<template>
  <label
    class="font-semibold"
    :class="[
      styles.themes[theme],
      styles.sizes[size],
      styles.round[rounded]
    ]"
  >
    <slot />
  </label>
</template>

<script setup>
defineOptions({
  name: 'AtomsBadge'
})

defineProps({
  theme: {
    type: String,
    default: 'primary',
    validator: value => ['primary', 'secondary', 'tertiary', 'light', 'destructive', 'warn', 'dark', 'theme-dark'].includes(value)
  },

  size: {
    type: String,
    default: 'standard',
    validator: value => ['standard', 'small', 'sm', 'medium', 'large'].includes(value)
  },

  rounded: {
    type: String,
    default: 'standard',
    validator: value => ['standard', 'full', 'custom'].includes(value)
  }
})

// variables
const styles = computed(() => {
  return {
    themes: {
      primary: 'bg-primary text-white',
      secondary: 'bg-transparent border-dark border text-dark ',
      tertiary: 'bg-transparent border-0 text-dark underline ',
      light: 'bg-transparent border border-white text-white ',
      destructive: 'bg-pink-600 text-white',
      warn: 'bg-yellow-600 text-dark',
      dark: 'bg-blue-900 text-white',
      'theme-dark': 'bg-dark text-white'
    },
    sizes: {
      standard: 'py-1.5 px-3 text-xs',
      small: 'badge-xs',
      sm: 'badge-sm',
      medium: 'py-3 px-3 text-base',
      large: 'py-4 px-3 text-lg'
    },
    round: {
      standard: 'rounded-sm',
      full: 'rounded-full',
      custom: ''
    }
  }
})
</script>

<style lang="scss" scoped>
@import url(./index.scss);
</style>
